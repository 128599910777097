@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-Regular.ttf');
}
@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-SemiBold.ttf');
  font-weight: 500;
}

body {
  font-family: 'LMSans', sans-serif !important;
}
